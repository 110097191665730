import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Modal from '@material-ui/core/Modal'
import CircularProgress from '@material-ui/core/CircularProgress'

import {
  setUser,
  setAuth,
  clearAnswerData,
  clearEvalInfo,
  setQuestionIndex,
  setDuration,
  setNextAgrupadores,
  setHasBeenSkipped,
} from '../../actions'

import {
  ModalWrapper,
  Title,
  ModalContainer,
  LoaderContainer,
  H3,
  ModalAligner,
  Bold,
  Text,
  ButtonsContainer,
  Button,
} from './styles'

const NewModal = (props) => {
  const {
    title,
    subtitle,
    description,
    message,
    loading,
    type,
    open,
    handleClose,
    handleContinue,
    setUser,
    setAuth,
    clearAnswerData,
    clearEvalInfo,
    setQuestionIndex,
    tokenKey,
    setDuration,
    setNextAgrupadores,
    setHasBeenSkipped,
  } = props

  const handleClick = () => {
    handleClose()
  }

  const handleDeAuth = () => {
    handleClose()
    clearAnswerData()
    clearEvalInfo()
    setQuestionIndex(0)
    setDuration(null)
    setUser({})
    setAuth(false)
    window.localStorage.removeItem(tokenKey)
    window.location.reload()
  }

  const handleExit = () => {
    handleClose()
    clearAnswerData()
    clearEvalInfo()
    setQuestionIndex(0)
    setDuration(null)
    setNextAgrupadores({})
    setHasBeenSkipped(false)
  }

  const getButtons = (type) => {
    switch (type) {
      case 'auth':
        return (
          <>
            <Button color="#878787" onClick={handleClick}>
              Cancelar
            </Button>
            <Button color="#000" onClick={handleDeAuth}>
              Aceptar
            </Button>
          </>
        )

      case 'eval':
        return (
          <>
            <Button color="#000" onClick={handleContinue}>
              Continuar
            </Button>
            <Link to="/">
              <Button color="#878787" onClick={handleExit}>
                Salir
              </Button>
            </Link>
          </>
        )

      case 'eval-timing':
        return (
          <>
            <Button color="#000" onClick={handleClick}>
              Aceptar
            </Button>
          </>
        )

      case 'eval-timeout':
        return <></>

      case 'results':
        return (
          <>
            <Button color="#878787" onClick={handleClick}>
              Salir
            </Button>
          </>
        )

      default:
        return (
          <>
            <Button color="#878787" onClick={handleClick}>
              Cancelar
            </Button>
            <Link to="/">
              <Button color="#000" onClick={handleExit}>
                Salir
              </Button>
            </Link>
          </>
        )
    }
  }

  return (
    <Modal open={open}>
      <ModalAligner>
        <ModalWrapper>
          <Title>
            <H3>{title}</H3>
          </Title>
          {loading ? (
            <LoaderContainer>
              <CircularProgress size={60} color="secondary" />
            </LoaderContainer>
          ) : (
            <>
              {type === 'results' ? (
                <ModalContainer>
                  <Text style={{ color: '#026eb7', margin: '3px 0' }}>
                    {subtitle}
                  </Text>
                  <Text style={{ margin: '3px 0' }}>{description}</Text>
                  {message && (
                    <>
                      <Bold>Retroalimentación:</Bold>
                      <Text style={{ margin: '3px 0' }}>{message}</Text>
                    </>
                  )}
                  <ButtonsContainer>{getButtons(type)}</ButtonsContainer>
                </ModalContainer>
              ) : (
                <ModalContainer>
                  <Text
                    style={{
                      margin: type === 'eval-timeout' ? '3px 30px' : '3px 0',
                      textAlign: type === 'eval-timeout' && 'left',
                    }}
                  >
                    {description}
                  </Text>
                  {message && (
                    <Text style={{ margin: '3px 0' }}>{message}</Text>
                  )}
                  <ButtonsContainer>{getButtons(type)}</ButtonsContainer>
                </ModalContainer>
              )}
            </>
          )}
        </ModalWrapper>
      </ModalAligner>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  tokenKey: state.tokenKey,
})

const mapDispatchToProps = {
  setUser,
  setAuth,
  clearAnswerData,
  clearEvalInfo,
  setQuestionIndex,
  setDuration,
  setNextAgrupadores,
  setHasBeenSkipped,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewModal)
