import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { setAnswerData } from '../actions'
import Progress from '../components/Progress'
import { ButtonExit } from '../components/Buttons'

const PreguntaContainer = styled.div`
  margin: 1rem 3rem 0;
  @media screen and (max-width: 480px) {
    margin: 1rem 1rem 0;
  }
  padding-left: env(safe-area-inset-left);
`

const InputContainer = styled.div`
  margin: 38px;
  display: flex;
  align-items: center;
  margin-bottom: 8em;
  position: relative;

  @media screen and (max-width: 1366px) {
    margin-bottom: 6em;
  }

  @media screen and (max-width: 768px) {
    margin: 38px 0 100px;
    flex-wrap: wrap;
  }
  padding-left: env(safe-area-inset-left);
`

const Wrapper = styled.div`
  @media screen and (min-width: 1440px) {
    display: grid;
    grid-template-rows: 2fr 1fr;
  }

  @media screen and (max-width: 1366px) {
    grid-template-rows: 1fr 1fr;
  }
`

const ControlsWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  z-index: 100;
  margin-bottom: 25px;
  padding-bottom: 10px;

  @media screen and (max-width: 600px) {
    padding-bottom: calc(5px + env(safe-area-inset-bottom));
  }
`

const Controls = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 15% 70% 15%;

  @media screen and (max-width: 480px) {
    grid-template-columns: 100%;
    width: 91%;
    margin: 0 auto;
  }
  @media screen and (orientation: landscape) and (min-device-width: 375px) and (max-device-width: 812px) {
    width: 91%;
    margin: 0 auto;
  }
`

const Text = styled.p`
  font-size: 21px;
  margin: 0 10px 0 0;
  @media screen and (max-width: 768px) {
    margin: 0 10px 10px 0;
  }
`

const Input = styled.input`
  border: none;
  background-color: #e2e2e2;
  width: 100%;
  font-size: 21px;
  height: 33px;

  &:placeholder-shown {
    font-size: 21px;
  }
`

const Name = styled.p`
  font-size: 24px;
  text-align: center;
`

const Question = styled.p`
  font-size: 24px;
`

const CounterText = styled.span`
  position: absolute;
  right: 0;
  top: -23px;
`

const PreguntaAbierta = ({
  agrupadores,
  reactivos,
  questionIndex,
  answerData,
  setAnswerData,
  asignaciones,
}) => {
  const [canContinue, setCanContinue] = useState(false)

  const matches = useMediaQuery('(max-width: 480px)')

  const agrupadorActual = agrupadores.filter((agrupador) =>
    agrupador.reactivos.includes(reactivos[questionIndex])
  )[0]

  const respuestaActual = answerData.respuestas.find(
    (respuesta) => respuesta.id_reactivo === reactivos[questionIndex].id
  )

  const handleChange = (ev) => {
    if (
      answerData.respuestas.find(
        (respuesta) => respuesta.id_agrupador === agrupadorActual.id
      )
    ) {
      const respuestasFiltradas = answerData.respuestas.filter(
        (respuesta) => respuesta.id_reactivo !== reactivos[questionIndex].id
      )

      setAnswerData({
        ...answerData,
        respuestas: [
          ...respuestasFiltradas,
          {
            id_respuesta: reactivos[questionIndex].opcions[0].id,
            id_agrupador: agrupadorActual.id,
            id_reactivo: reactivos[questionIndex].id,
            clave_materia: asignaciones[0].clave,
            clave_docente: asignaciones[0].clave_docente,
            id_asignacion: asignaciones[0].id,
            id_student_assignment: asignaciones[0].id_student_assignment,
            respuesta: ev.target.value,
            tipo: 'abierta',
          },
        ],
      })
    } else {
      setAnswerData({
        ...answerData,
        respuestas: [
          ...answerData.respuestas,
          {
            id_respuesta: reactivos[questionIndex].opcions[0].id,
            id_agrupador: agrupadorActual.id,
            id_reactivo: reactivos[questionIndex].id,
            clave_materia: asignaciones[0].clave,
            clave_docente: asignaciones[0].clave_docente,
            id_asignacion: asignaciones[0].id,
            id_student_assignment: asignaciones[0].id_student_assignment,
            respuesta: ev.target.value,
            tipo: 'abierta',
          },
        ],
      })
    }
  }

  if (respuestaActual) {
    const newCanContinue = respuestaActual.respuesta.length > 0
    canContinue !== newCanContinue && setCanContinue(newCanContinue)
  }

  const question = reactivos[questionIndex]?.pregunta

  return (
    <>
      <PreguntaContainer>
        <Name>{`${agrupadorActual?.numero}.${agrupadorActual?.nombre}`}</Name>

        <Question>{`${agrupadorActual?.numero}.${reactivos[questionIndex]?.numero} ${question}`}</Question>
        <Wrapper>
          <>
            <InputContainer>
              <Text>Respuesta:</Text>
              <Input
                type="text"
                placeholder="Escriba una respuesta"
                value={respuestaActual && respuestaActual.respuesta}
                onChange={handleChange}
                maxLength="1000"
              />
              <CounterText>
                {respuestaActual ? respuestaActual.respuesta.length : 0}/1000
              </CounterText>
            </InputContainer>
          </>
        </Wrapper>
      </PreguntaContainer>

      <ControlsWrapper>
        <Controls>
          {!matches && <ButtonExit />}
          <Progress canContinue={canContinue} />
        </Controls>
      </ControlsWrapper>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    agrupadores: state.agrupadores,
    reactivos: state.reactivos,
    questionIndex: state.questionIndex,
    answerData: state.answerData,
    asignaciones: state.asignaciones,
  }
}

const mapDispatchToProps = {
  setAnswerData,
}

export default connect(mapStateToProps, mapDispatchToProps)(PreguntaAbierta)
