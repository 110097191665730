import React from 'react'
import { useHistory } from 'react-router-dom'
import { css } from 'glamor'
import { LeftArrow, RightArrow } from '@styled-icons/boxicons-solid'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { connect } from 'react-redux'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Checkpoint from './Checkpoint'

import { postRequest } from '../../utils/requester'

import {
  setQuestionIndex,
  setEvalInfo,
  setAnswerData,
  setIndexHistory,
  setNextAgrupadores,
  setHasBeenSkipped,
  setAgrupadorIndexHistory,
} from '../../actions'

import {
  Container,
  StepperContainer,
  ItemContainer,
  ButtonsContainer,
  Button,
  ProgressBarContainer,
  Bar,
  ImageContainer,
  Image,
  PercentText,
  ButtonLabel,
} from './styles'

import pointer from '../../assets/images/progress_pointer.png'

const Progress = ({
  canContinue,
  disabled,
  answerData,
  type,
  questionIndex,
  agrupadores,
  setQuestionIndex,
  enviar,
  reactivos,
  isList,
  renderedTables,
  tokenKey,
  indexHistory,
  setIndexHistory,
  nextAgrupadores,
  setHasBeenSkipped,
  setAgrupadorIndexHistory,
  agrupadorIndexHistory,
  hasBeenSkipped,
  asignaciones,
}) => {
  const history = useHistory()

  const now = parseInt((questionIndex / reactivos.length) * 100)

  const matches = useMediaQuery('(min-width: 769px)')

  let agrupadorActual

  agrupadores.forEach((agrupador) => {
    if (agrupador.reactivos.includes(reactivos[questionIndex])) {
      agrupadorActual = agrupador
    }
  })

  let agrupadorSiguiente

  agrupadores.forEach((agrupador) => {
    if (agrupador.reactivos.includes(reactivos[questionIndex + 1])) {
      agrupadorSiguiente = agrupador
    }
  })

  let reactivosActuales = []
  for (let i = 0; i < agrupadores.length; i++) {
    reactivosActuales.push(...agrupadores[i].reactivos)
    if (JSON.stringify(agrupadores[i]) === JSON.stringify(agrupadorActual)) {
      break
    }
  }

  if (questionIndex >= reactivos.length) canContinue = true

  const sendAnswers = () => {
    const respuestas = answerData.respuestas.map((respuesta) => {
      if (
        answerData.tipo !== 'Opinion_Estudiantil' ||
        answerData.tipo !== 'Evaluacion_Temprana'
      ) {
        return {
          id_respuesta: respuesta.id_respuesta,
          id_agrupador: respuesta.id_agrupador,
          id_reactivo: respuesta.id_reactivo,
          id_asignacion: respuesta.id_asignacion,
          id_student_assignment: respuesta.id_student_assignment,
          respuesta: respuesta.respuesta,
          tipo: respuesta.tipo,
        }
      }
      return {
        id_respuesta: respuesta.id_respuesta,
        id_agrupador: respuesta.id_agrupador,
        id_reactivo: respuesta.id_reactivo,
        clave_materia: respuesta.clave_materia,
        clave_docente: respuesta.clave_docente,
        id_asignacion: respuesta.id_asignacion,
        id_student_assignment: respuesta.id_student_assignment,
        respuesta: respuesta.respuesta,
        tipo: respuesta.tipo,
      }
    })

    const answers = respuestas.slice(
      questionIndex,
      questionIndex + renderedTables
    )

    const newAnswerData = {
      ...answerData,
      respuestas: answers,
    }

    try {
      postRequest('/evaluacion/responder', newAnswerData)
    } catch (error) {
      console.log(error)
    }
  }

  const goBack = () => {
    if (questionIndex > 0) {
      if (isList) {
        if (questionIndex - indexHistory[indexHistory.length - 1] < 0) {
          setQuestionIndex(0)
        } else {
          if (hasBeenSkipped) {
            history.location.pathname === '/envio' && history.goBack()

            if (agrupadorIndexHistory.length > 0) {
              const newAgrupadorIndexHistory = [...agrupadorIndexHistory]
              const lastAgrupadorIndex = newAgrupadorIndexHistory.pop()
              setAgrupadorIndexHistory([...newAgrupadorIndexHistory])
              setQuestionIndex(lastAgrupadorIndex)
            } else {
              setQuestionIndex(0)
            }

            const newIndexHistory = [...indexHistory]
            newIndexHistory.pop()
            setIndexHistory([...newIndexHistory])
          } else {
            history.location.pathname === '/envio' && history.goBack()

            setQuestionIndex(
              questionIndex - indexHistory[indexHistory.length - 1]
            )
            const newIndexHistory = [...indexHistory]
            newIndexHistory.pop()
            setIndexHistory([...newIndexHistory])
          }
        }
      } else {
        if (history.entries[history.index - 1].pathname === '/presentacion') {
          history.go(-2)
          setQuestionIndex(questionIndex - 1)
          return
        }
        if (history.location.pathname === '/presentacion') {
          history.goBack()
        } else {
          history.goBack()
          setQuestionIndex(questionIndex - 1)
        }
      }
    }
  }

  const handleNext = () => {
    if (!disabled) {
      if (enviar) {
        setQuestionIndex(0)
        setIndexHistory([])
        history.push('/fin')
        return
      }

      const curNextAgrupador =
        agrupadorActual && nextAgrupadores[agrupadorActual.nombre]

      if (curNextAgrupador && curNextAgrupador.length > 0) {
        const count = answerData.respuestas.reduce((acc, cur) => {
          if (cur.id_agrupador === agrupadorActual.id) {
            return acc + 1
          } else {
            return acc
          }
        }, 0)

        if (count === curNextAgrupador.length) {
          canContinue = true
        }
      } else {
        const count = answerData.respuestas.reduce((acc, cur) => {
          if (cur.id_agrupador === agrupadorActual.id) {
            return acc + 1
          } else {
            return acc
          }
        }, 0)

        if (count === asignaciones.length) {
          canContinue = true
        }
      }
      if (!canContinue) {
        toast('Para continuar se debe responder', {
          className: css({
            background: '#e2e2e2',
            color: 'black',
          }),
          progressClassName: css({
            background: '#026eb7',
          }),
        })
      } else {
        if (enviar) {
          setQuestionIndex(0)
          setIndexHistory([])
          history.push('/fin')
        } else {
          if (isList) {
            type === 'Evaluaciones' && sendAnswers()

            if (
              questionIndex + renderedTables < reactivos.length ||
              hasBeenSkipped
            ) {
              if (hasBeenSkipped) {
                if (
                  agrupadores[agrupadores.length - 1].nombre ===
                  agrupadorActual.nombre
                ) {
                  setQuestionIndex(questionIndex + renderedTables)
                  setIndexHistory([...indexHistory, renderedTables])
                  history.push('/envio')
                  return
                }
              }

              if (questionIndex + 5 < reactivosActuales.length) {
                setQuestionIndex(questionIndex + 5)
                setIndexHistory([...indexHistory, 5])
                if (agrupadorActual.orden !== agrupadorSiguiente.orden) {
                  history.push('/presentacion')
                }
              } else if (reactivosActuales.length - questionIndex === 0) {
                setQuestionIndex(questionIndex + 1)
                setIndexHistory([...indexHistory, 1])
                history.push('/presentacion')
              } else {
                if (
                  agrupadorActual.reactivos[0]['condiciones_redireccion'] &&
                  agrupadorActual.reactivos[0]['condiciones_redireccion']
                    .length > 0
                ) {
                  let nextAgrupador

                  const sortedAgrupadores = agrupadores.sort(
                    (a, b) => a.numero - b.numero
                  )

                  sortedAgrupadores.forEach((item, i) => {
                    if (
                      nextAgrupadores[item.nombre] &&
                      nextAgrupadores[item.nombre].length > 0
                    ) {
                      if (!nextAgrupador || i < nextAgrupador) {
                        if (i > questionIndex) {
                          nextAgrupador = i
                        }
                      }
                    }
                  })

                  // setNextAgrupadores({})
                  setHasBeenSkipped(true)
                  if (!nextAgrupador) {
                    setQuestionIndex(
                      questionIndex + (reactivosActuales.length - questionIndex)
                    )
                    setAgrupadorIndexHistory([
                      ...agrupadorIndexHistory,
                      questionIndex +
                        (reactivosActuales.length - questionIndex),
                    ])
                  } else {
                    setQuestionIndex(nextAgrupador)
                    setAgrupadorIndexHistory([
                      ...agrupadorIndexHistory,
                      nextAgrupador,
                    ])
                  }
                  setIndexHistory([...indexHistory, renderedTables])
                  history.push('/presentacion')
                } else {
                  // setNextAgrupadores({})
                  setQuestionIndex(
                    questionIndex + (reactivosActuales.length - questionIndex)
                  )
                  setAgrupadorIndexHistory([
                    ...agrupadorIndexHistory,
                    questionIndex + (reactivosActuales.length - questionIndex),
                  ])
                  setIndexHistory([...indexHistory, renderedTables])
                  history.push('/presentacion')
                }
              }
            } else {
              setQuestionIndex(questionIndex + renderedTables)
              setIndexHistory([...indexHistory, renderedTables])
              history.push('/envio')
            }
          } else {
            if (questionIndex + 1 < reactivos.length) {
              setQuestionIndex(questionIndex + 1)
              if (
                JSON.stringify(agrupadorActual) !==
                JSON.stringify(agrupadorSiguiente)
              ) {
                history.push('/presentacion')
              } else {
                if (reactivos[questionIndex + 1].tipo === 'seleccion simple') {
                  history.push('/seleccion')
                } else if (
                  reactivos[questionIndex + 1].tipo === 'seleccion multiple'
                ) {
                  history.push('/opcion-multiple')
                } else {
                  history.push(`/${reactivos[questionIndex + 1].tipo}`)
                }
              }
            } else {
              setQuestionIndex(questionIndex + 1)
              history.push('/envio')
            }
          }
        }
      }
    } else {
      if (isList) {
        history.push('/multiple')
      } else {
        if (reactivos[questionIndex].tipo === 'seleccion simple') {
          history.push('/seleccion')
        } else {
          history.push(`/${reactivos[questionIndex].tipo}`)
        }
      }
    }
  }

  return (
    <Container>
      <ButtonsContainer>
        <Button onClick={goBack}>
          <LeftArrow size={18} />
        </Button>
        <Button onClick={handleNext}>
          <RightArrow size={18} />
        </Button>
      </ButtonsContainer>
      <StepperContainer>
        <ItemContainer>
          <ButtonLabel>0%</ButtonLabel>
          {/* <Button onClick={goBack}>
          <LeftArrow size={18} />
        </Button> */}
        </ItemContainer>
        <ProgressBarContainer>
          <Bar percent={`${now}%`}>
            {/* <ImageContainer show={matches && now > 0}>
            <Image src={pointer} alt='pointer' />
            <PercentText now={now}>{now}%</PercentText>
          </ImageContainer> */}
          </Bar>
          {/* <Checkpoint number={0} percent={0} />
        {agrupadores.slice(0, agrupadores.length - 1).map((agrupador, i) => (
          <Checkpoint
            key={i}
            number={i + 1}
            percent={(agrupador.reactivos.length / reactivos.length) * 100}
          />
        ))} */}
        </ProgressBarContainer>
        <ItemContainer>
          <ButtonLabel>100%</ButtonLabel>
          {/* <Button onClick={handleNext}>
          <RightArrow size={18} />
        </Button> */}
        </ItemContainer>
      </StepperContainer>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  questionIndex: state.questionIndex,
  agrupadores: state.agrupadores,
  reactivos: state.reactivos,
  answerData: state.answerData,
  type: state.type,
  evalInfo: state.evalInfo,
  isList: state.isList,
  renderedTables: state.renderedTables,
  tokenKey: state.tokenKey,
  indexHistory: state.indexHistory,
  nextAgrupadores: state.nextAgrupadores,
  agrupadorIndexHistory: state.agrupadorIndexHistory,
  hasBeenSkipped: state.hasBeenSkipped,
  asignaciones: state.asignaciones,
})

const mapDispatchToProps = {
  setQuestionIndex,
  setEvalInfo,
  setAnswerData,
  setIndexHistory,
  setNextAgrupadores,
  setHasBeenSkipped,
  setAgrupadorIndexHistory,
}

export default connect(mapStateToProps, mapDispatchToProps)(Progress)
