import React from 'react'
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { setAuthModal } from '../../actions'
import Modal from '../Modal'
import {
  HeadBarContainer,
  HeadBar,
  LogoContainer,
  ProfileContainer,
  AvatarContainer,
  Text,
  AvatarIconStyled,
  Img,
} from './styles'

import logo from '../../assets/images/ICONOS-19.png'

const Header = ({ user, setAuthModal, isAuthModalOpen }) => {
  return (
    <>
      <HeadBarContainer id="header">
        <HeadBar>
          <LogoContainer>
            <Img src={logo} alt="logo" />
          </LogoContainer>
          <ProfileContainer>
            <div>
              <Text>
                {`${user.nombre || ''} ${user.apellido_paterno || ''} ${
                  user.apellido_materno || ''
                }`}
              </Text>
              <Text>{user.rol}</Text>
            </div>
            <AvatarContainer>
              <AvatarIconStyled onClick={(e) => setAuthModal(true)} />
            </AvatarContainer>
          </ProfileContainer>
        </HeadBar>
      </HeadBarContainer>
      <Modal
        title="Cerrar sesión"
        description="¿Seguro que desea cerrar sesión?"
        type="auth"
        open={isAuthModalOpen}
        handleClose={(e) => setAuthModal(false)}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    isAuthModalOpen: state.isAuthModalOpen,
    user: state.user,
  }
}

const mapDispatchToProps = {
  setAuthModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
