import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { connect } from 'react-redux'

import Table from '../components/NewTable'
import { setEvals, setLoading, setRawType } from '../actions'

import { getRequest } from '../utils/requester'

const WelcomeContainer = styled.div`
  display: flex;
  justify-content: center;
`

const TableContainer = styled.div`
  margin: 0 50px 20px;
  @media screen and (max-width: 480px) {
    margin: 0 20px 50px;
  }
`

const Welcome = styled.h1`
  font-size: 28px;
  font-weight: 300;
  margin: 50px 0;

  @media screen and (max-width: 1024px) {
    font-size: 26px;
  }

  @media screen and (max-width: 768px) {
    margin: 28px 0;
  }

  @media screen and (max-width: 480px) {
    margin: 26px 0;
    font-size: 25px;
  }
`

const H4 = styled.h4`
  font-size: 23px;
  margin-top: 0;
  font-weight: 300;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }

  @media screen and (max-width: 480px) {
    font-weight: 300;
    font-size: 16px;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`

const Button = styled.button`
  background-color: ${({ isDisabled }) => (isDisabled ? '#6e6e6e' : '#026eb7')};
  color: white;
  border-radius: 10px;
  width: 100px;
  height: 20px;
  cursor: pointer;
  font-size: 15px;
  border: none;

  @media screen and (max-width: 600px) {
    border-radius: 9px;
    height: 25px;
  }

  @media screen and (max-width: 480px) {
    height: 26px;
    width: 90px;
  }

  @media screen and (max-width: 425px) {
    height: 24px;
    width: 85px;
    font-size: 14px;
  }

  @media screen and (max-width: 320px) {
    height: 22px;
    width: 80px;
    border-radius: 8px;
    font-size: 13px;
  }
`

const Home = ({ user, tokenKey, setEvals, setLoading, setRawType }) => {
  const urlParameters = new URLSearchParams(window.location.search).get('data')
  const isAuth = window.localStorage.getItem(tokenKey) || urlParameters
  const history = useHistory()
  const headers = [
    {
      name: 'Tipo de Programación',
      width: '',
      center: false,
    },
    {
      name: 'Periodo',
      width: '',
      center: false,
    },
    {
      name: 'Estatus',
      width: '140px',
      center: true,
    },
    {
      name: 'Revisar',
      width: '120px',
      center: true,
    },
  ]

  useEffect(() => {
    setRawType('')
    if (isAuth) {
      getEvals()
    }
  }, [])

  const getEvals = async () => {
    if (isAuth) {
      setLoading(true)
      try {
        const data = await getRequest('/evaluacion/alumno')
        setEvals(data.d)
        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    }
  }

  return (
    <>
      <WelcomeContainer>
        <Welcome>Bienvenido {user.nombre || ''}</Welcome>
      </WelcomeContainer>
      <TableContainer>
        <H4>A continuación se muestran las evaluaciones por contestar</H4>
        <Table />
      </TableContainer>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    tokenKey: state.tokenKey,
  }
}

const mapDispatchToProps = {
  setEvals,
  setLoading,
  setRawType,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
