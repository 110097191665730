import styled from 'styled-components'

export const RangeContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 110px 30px;
`

export const Bar = styled.div`
  border: 2px solid gray;
  width: 13vw;
  height: 10px;
  margin: -3px;
  border-right: none;
  border-left: none;
  background-color: #fff;
  z-index: 50;
  background-color: ${({ selected }) => (selected ? '#026eb7' : '#fff')};
`

export const Radius = styled.div`
  border: 2px solid gray;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
`

export const Color = styled.div`
  z-index: 60;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 50%;
  background-color: ${({ selected }) => (selected ? '#026eb7' : '#fff')};
  position: relative;
`

export const Number = styled.span`
  position: relative;
  top: -90px;
  left: -25px;
  cursor: initial;
`
