import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useMediaQuery } from '@material-ui/core'

import { setAnswerData, setRenderedTables } from '../actions'
import SeleccionTable from '../components/SeleccionTable'
import IntervaloTable from '../components/IntervaloTable'
import AbiertaTable from '../components/AbiertaTable'
import OpcionMultipleTable from '../components/OpcionMultipleTable'
import SeleccionItem from '../components/SeleccionItem'
import IntervaloItem from '../components/IntervaloItem'
import AbiertaItem from '../components/AbiertaItem'
import OpcionMultipleItem from '../components/OpcionMultipleItem'
import Progress from '../components/Progress'
import { ButtonExit, ButtonNext } from '../components/Buttons'

const InicioContainer = styled.div`
  margin: 1rem 1rem 125px;
  @media screen and (max-width: 480px) {
    margin: 1rem 0.5rem 125px;
  }
  padding-left: env(safe-area-inset-left);
`

const ControlsWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  z-index: 100;
  margin-bottom: 25px;
  padding-bottom: 10px;

  @media screen and (max-width: 600px) {
    padding-bottom: calc(5px + env(safe-area-inset-bottom));
  }
`

const Controls = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 15% 70% 15%;

  @media screen and (max-width: 480px) {
    grid-template-columns: 100%;
    width: 91%;
    margin: 0 auto;
  }
  @media screen and (orientation: landscape) and (min-device-width: 375px) and (max-device-width: 812px) {
    width: 91%;
    margin: 0 auto;
  }
`

const Name = styled.p`
  font-size: 24px;
  text-align: center;
`

const Question = styled.p`
  font-size: 24px;
`

const FormContainer = styled.div`
  max-height: calc(100% - 330px);
  overflow: auto;
  padding: 0 1rem;
  @media screen and (max-width: 1366px) {
    margin: 0 1rem 2rem;
  }

  @media screen and (max-width: 768px) {
    margin: 0;
  }

  @media screen and (max-width: 480px) {
    max-height: 340px;
  }
  ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
`

const Multiple = ({
  agrupadores,
  asignaciones,
  questionIndex,
  reactivos,
  answerData,
  setRenderedTables,
  renderedTables,
  type,
}) => {
  const [canContinue, setCanContinue] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [questionIndex])

  let agrupadorActual

  agrupadores.forEach((agrupador) => {
    if (agrupador.reactivos.includes(reactivos[questionIndex])) {
      agrupadorActual = agrupador
    }
  })

  const matches = useMediaQuery('(min-width:480px)')

  let reactivosActuales = []
  for (let i = 0; i < agrupadores.length; i++) {
    reactivosActuales.push(...agrupadores[i].reactivos)
    if (JSON.stringify(agrupadores[i]) === JSON.stringify(agrupadorActual)) {
      break
    }
  }

  let reactivosNum = (questionIndex + renderedTables) * asignaciones.length

  const newCanContinue = answerData.respuestas.length >= reactivosNum
  canContinue !== newCanContinue && setCanContinue(newCanContinue)

  const TableToRender = ({ reactivo, index }) => {
    switch (reactivo.tipo) {
      case 'seleccion simple':
        return <SeleccionTable reactivo={reactivo} index={index} />

      case 'intervalo':
        return <IntervaloTable reactivo={reactivo} index={index} />

      case 'abierta':
        return <AbiertaTable reactivo={reactivo} index={index} />

      case 'seleccion multiple':
        return <OpcionMultipleTable reactivo={reactivo} index={index} />

      default:
        return <p></p>
    }
  }

  const QuestionToRender = ({ reactivo, index }) => {
    switch (reactivo.tipo) {
      case 'seleccion simple':
        return <SeleccionItem reactivo={reactivo} index={index} />

      case 'intervalo':
        return <IntervaloItem reactivo={reactivo} index={index} />

      case 'abierta':
        return <AbiertaItem reactivo={reactivo} index={index} />

      case 'seleccion multiple':
        return <OpcionMultipleItem reactivo={reactivo} index={index} />

      default:
        return <p>Not implemented</p>
    }
  }

  let iterations = reactivosActuales.slice(
    questionIndex,
    questionIndex + 5
  ).length
  reactivosActuales.slice(questionIndex, questionIndex + 5).forEach((_, n) => {
    if (!--iterations) {
      if (n + 1 !== renderedTables) {
        setRenderedTables(n + 1)
      }
    }
  })

  return (
    <>
      <InicioContainer>
        <Name>{`${agrupadorActual?.numero}.${agrupadorActual?.nombre}`}</Name>
        {reactivosActuales
          .slice(questionIndex, questionIndex + 5)
          .map((reactivo, i) => (
            <div key={i}>
              <Question>{`${agrupadorActual?.numero}.${reactivo.numero} ${reactivo.pregunta}`}</Question>
              <FormContainer>
                {type === 'Evaluaciones' ? (
                  <QuestionToRender reactivo={reactivo} index={i} />
                ) : (
                  <TableToRender reactivo={reactivo} index={i} />
                )}
              </FormContainer>
            </div>
          ))}
        <ButtonNext canContinue={canContinue} />
      </InicioContainer>

      <ControlsWrapper>
        <Controls>
          {matches && <ButtonExit />}
          <Progress canContinue={canContinue} />
        </Controls>
      </ControlsWrapper>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    agrupadores: state.agrupadores,
    asignaciones: state.asignaciones,
    questionIndex: state.questionIndex,
    reactivos: state.reactivos,
    answerData: state.answerData,
    evalInfo: state.evalInfo,
    renderedTables: state.renderedTables,
    type: state.type,
  }
}

const mapDispatchToProps = {
  setAnswerData,
  setRenderedTables,
}

export default connect(mapStateToProps, mapDispatchToProps)(Multiple)
