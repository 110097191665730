import styled from 'styled-components'

export const PreguntasContainer = styled.div`
  margin: 1rem 3rem 0;
  margin-bottom: 0px;
  height: 70%;
  @media screen and (max-width: 480px) {
    margin: 1rem 1rem 0;
  }
  padding-left: env(safe-area-inset-left);
`

export const OptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 6em;
  width: 60%;

  @media screen and (min-width: 769px) and (max-width: 1366px) {
    margin-bottom: 4em;
  }
`

export const Option = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 4px;
  justify-content: space-between;
`

export const SquareCheck = styled.label`
  width: ${({ size }) => (size ? size : '25px')};
  height: ${({ size }) => (size ? size : '25px')};
  border: 2px solid gray;
  cursor: pointer;
  background-color: ${({ checked }) => (checked ? '#026eb7' : 'white')};
`

export const TextLabel = styled.p`
  text-align: center;
`
