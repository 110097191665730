import styled from 'styled-components'

export const FormPage = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #026eb7;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Image = styled.img`
  max-width: 380px;
  width: 80vw;
  margin-bottom: 3em;

  @media screen and (max-width: 768px) {
    margin-bottom: 3em;
  }
`

export const Form = styled.form`
  max-width: 380px;
  width: 80vw;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  @media screen and (max-width: 425px) {
    margin-top: 30px;
  }
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 50px;
  background-color: #0007;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 13px;
  padding: 0 30px;
`

export const ButtonText = styled.p`
  font-size: 17px;
  color: #fff;
  margin-right: 10px;
`

export const LoaderContainer = styled.div`
  padding-top: 3px;
  display: ${({ show }) => (show ? 'block' : 'none')};
`

export const Text = styled.span`
  display: ${({ show }) => (show ? 'block' : 'none')};
`

export const Text1 = styled.h1`
  font-weight: 550;
  text-align: center;
  color: white;
  margin-top: 10px;
  font-size: 20px;
  margin-bottom: 0px;
`

export const Title = styled.h1`
  text-align: center;
  color: white;
  margin-top: 10px;
  font-size: 40px;
  margin-bottom: 0px;
`

export const ExternalLink = styled.a`
  position: absolute;
  bottom: 30px;
  left: 5px;
  color: #fff;
  text-decoration: underline;
  font-size: 20px;
`
