import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StepperContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  width: 100%;
  margin: 0 auto;
`

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 20px;
  border-radius: 15px;
  border: 1px solid #999;
  margin: 0 5px 3px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  position: relative;

  @media screen and (max-width: 480px) {
    height: 10px;
    margin: 0 20px 10px;
  }
  @media screen and (orientation: landscape) and (min-device-width: 375px) and (max-device-width: 812px) {
    height: 10px;
    margin: 0 20px 10px;
  }
`

export const Bar = styled.div`
  width: ${({ percent }) => percent};
  height: inherit;
  background-color: #026eb7;
  border-radius: 15px;
  justify-content: flex-end;
  display: flex;
  align-items: flex-end;
  position: absolute;
`

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ButtonsContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
`

export const Button = styled.button`
  color: white;
  background-color: #026eb7;
  width: 53px;
  border-radius: 20px;
  padding: 6px 11px;
  margin: 0 3px;
  border: none;
  cursor: pointer;
`

export const ImageContainer = styled.div`
  position: relative;
  ${({ show }) =>
    !show &&
    css`
      display: none;
    `}
`

export const Image = styled.img`
  width: 60px;
  position: absolute;
  top: -100px;
  left: -30px;
`

export const PercentText = styled.span`
  color: white;
  position: absolute;
  top: -80px;
  left: ${({ now }) => (now >= 100 ? '-19px' : '-15px')};
`

export const CheckpointContainer = styled.div`
  height: 20px;
  width: ${({ percent }) => percent};
  position: relative;
`

export const Point = styled.div`
  height: 36px;
  width: 36px;
  border-radius: 50%;
  position: absolute;
  right: -18px;
  top: -8px;
  background-color: ${({ isActive }) => (isActive ? '#026eb7' : '#c5c5c5')};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  @media screen and (max-width: 480px) {
    height: 26px;
    width: 26px;
    right: -13px;
  }
  @media screen and (orientation: landscape) and (min-device-width: 375px) and (max-device-width: 812px) {
    height: 26px;
    width: 26px;
    right: -13px;
  }
`

export const ButtonLabel = styled.span`
  margin-top: 5px;
  margin-bottom: 5px;
`
