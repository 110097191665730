import { createStore } from 'redux'
import reducer from './reducers'

const initialState = {
  user: {},
  isLoading: false,
  loadingRespuesta: false,
  isModalOpen: false,
  isAuthModalOpen: false,
  errorEvaluacion: '',
  rawType: '',
  duration: null,
  type: '',
  version: '',
  evals: [],
  syllabus: [],
  detalleSyllabus: {},
  asignaciones: [],
  agrupadores: [],
  evalInfo: null,
  questionIndex: 0,
  reactivos: [],
  answerData: {},
  isList: false,
  renderedTables: 0,
  showFixed: false,
  tokenKey: '',
  indexHistory: [],
  nextAgrupadores: {},
  hasBeenSkipped: false,
  agrupadorIndexHistory: [],
}

const store = createStore(
  reducer,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store
