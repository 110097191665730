import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Progress from '../components/Progress'
import { ButtonExit, ButtonNext } from '../components/Buttons'
import { setAnswerData } from '../actions'

const Container = styled.div`
  margin: 1rem 3rem 115px;
  height: 100%;
  overflow-y: scroll;
  padding-left: env(safe-area-inset-left);
  ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  @media screen and (max-width: 480px) {
    margin: 1rem 1rem 115px;
  }
`
const FormContainer = styled.div`
  max-height: calc(100% - 330px);
  overflow: auto;
  @media screen and (max-width: 1366px) {
    margin: 0 1rem 2rem;
  }

  @media screen and (max-width: 768px) {
    margin: 0;
  }

  @media screen and (max-width: 480px) {
    max-height: 340px;
  }
  ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
`

const ControlsWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  z-index: 100;
  margin-bottom: 25px;
  padding-bottom: 10px;

  @media screen and (max-width: 600px) {
    padding-bottom: calc(5px + env(safe-area-inset-bottom));
  }
`

const Controls = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 15% 70% 15%;

  @media screen and (max-width: 480px) {
    grid-template-columns: 100%;
    width: 91%;
    margin: 0 auto;
  }
  @media screen and (orientation: landscape) and (min-device-width: 375px) and (max-device-width: 812px) {
    width: 91%;
    margin: 0 auto;
  }
`

const Name = styled.p`
  font-size: 24px;
  text-align: center;
`

const Question = styled.p`
  font-size: 24px;
`

const RangeContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 110px 30px;
`

const Bar = styled.div`
  border: 2px solid gray;
  width: 13vw;
  height: 10px;
  margin: -3px;
  border-right: none;
  border-left: none;
  background-color: #fff;
  z-index: 50;
  background-color: ${({ selected }) => (selected ? '#026eb7' : '#fff')};
`

const Radius = styled.div`
  border: 2px solid gray;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
`

const Color = styled.div`
  z-index: 60;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 50%;
  background-color: ${({ selected }) => (selected ? '#026eb7' : '#fff')};
  position: relative;
`

const Number = styled.span`
  position: relative;
  top: -90px;
  left: -25px;
  cursor: initial;
`

const Intervalo = ({
  reactivos,
  questionIndex,
  answerData,
  asignaciones,
  agrupadores,
  evalInfo,
  setAnswerData,
  isList,
}) => {
  const [canContinue, setCanContinue] = useState(false)

  const question = reactivos[questionIndex]?.pregunta

  const respuestasActuales = answerData.respuestas.filter(
    (respuesta) =>
      respuesta.id_reactivo === reactivos[questionIndex].id &&
      respuesta.tipo === 'intervalo'
  )

  const agrupadorActual = agrupadores.filter((agrupador) =>
    agrupador.reactivos.includes(reactivos[questionIndex])
  )[0]

  const handleClick = (res, val, id_res) => {
    if (
      answerData.respuestas.find(
        (respuesta) => respuesta.id_agrupador === agrupadorActual.id
      )
    ) {
      const respuestasFiltradas = answerData.respuestas.filter(
        (respuesta) =>
          respuesta.id_reactivo !== reactivos[questionIndex].id ||
          respuesta.id_agrupador !== agrupadorActual.id
      )

      setAnswerData({
        ...answerData,
        respuestas: [
          ...respuestasFiltradas,
          {
            id_respuesta: id_res,
            id_agrupador: agrupadorActual.id,
            id_reactivo: reactivos[questionIndex].id,
            clave_materia: asignaciones[0].clave,
            clave_docente: asignaciones[0].clave_docente,
            id_asignacion: asignaciones[0].id,
            id_student_assignment: asignaciones[0].id_student_assignment,
            respuesta: res,
            valor_respuesta: val,
            tipo: 'intervalo',
          },
        ],
      })
    } else {
      setAnswerData({
        ...answerData,
        respuestas: [
          ...answerData.respuestas,
          {
            id_respuesta: id_res,
            id_agrupador: agrupadorActual.id,
            id_reactivo: reactivos[questionIndex].id,
            clave_materia: asignaciones[0].clave,
            clave_docente: asignaciones[0].clave_docente,
            id_asignacion: asignaciones[0].id,
            id_student_assignment: asignaciones[0].id_student_assignment,
            respuesta: res,
            valor_respuesta: val,
            tipo: 'intervalo',
          },
        ],
      })
    }
  }

  let name
  let numero

  if (isList) {
    name = agrupadores[questionIndex].nombre
    numero = agrupadores[questionIndex].numero
  } else {
    agrupadores.forEach((agrupador) => {
      if (agrupador.reactivos.includes(reactivos[questionIndex])) {
        name = agrupador.nombre
        numero = agrupador.numero
      }
    })
  }

  const matches = useMediaQuery('(min-width:481px)')

  const respuestaFiltrada = answerData.respuestas.filter(
    (respuesta) => reactivos[questionIndex].id === respuesta.id_reactivo
  )

  const newCanContinue = respuestaFiltrada.length > 0
  canContinue !== newCanContinue && setCanContinue(newCanContinue)

  return (
    <>
      <Container isList={isList}>
        <Name>{`${numero}.${name}`}</Name>
        <Question>{`${numero}.${reactivos[questionIndex]?.numero} ${question}`}</Question>
        <RangeContainer>
          {reactivos[questionIndex]?.opcions.map(({ nombre, id }, i) => (
            <>
              <Radius onClick={() => handleClick(nombre, i, id)}>
                <Color
                  selected={
                    respuestaFiltrada.length > 0 &&
                    respuestaFiltrada[0].valor_respuesta >= i
                  }
                />
                <Number>{nombre}</Number>
              </Radius>
              {reactivos[questionIndex].opcions.length > i + 1 && (
                <Bar
                  selected={
                    respuestaFiltrada.length > 0 &&
                    respuestaFiltrada[0].valor_respuesta > i
                  }
                />
              )}
            </>
          ))}
        </RangeContainer>
        <ButtonNext canContinue={canContinue} />
      </Container>

      <ControlsWrapper>
        <Controls>
          {matches && <ButtonExit />}
          <Progress canContinue={canContinue} />
        </Controls>
      </ControlsWrapper>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    reactivos: state.reactivos,
    questionIndex: state.questionIndex,
    answerData: state.answerData,
    asignaciones: state.asignaciones,
    agrupadores: state.agrupadores,
    evalInfo: state.evalInfo,
    isList: state.isList,
  }
}

const mapDispatchToProps = {
  setAnswerData,
}

export default connect(mapStateToProps, mapDispatchToProps)(Intervalo)
