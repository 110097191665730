import React, { useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  useMediaQuery,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { setAnswerData } from '../actions'
import Progress from '../components/Progress'
import { ButtonExit, ButtonNext } from '../components/Buttons'

const InicioContainer = styled.div`
  margin: 1rem 3rem 115px;
  height: 100%;
  overflow-y: scroll;
  padding-left: env(safe-area-inset-left);
  ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  @media screen and (max-width: 480px) {
    margin: 1rem 1rem 115px;
  }
`

const ControlsWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  z-index: 100;
  margin-bottom: 25px;
  padding-bottom: 10px;

  @media screen and (max-width: 600px) {
    padding-bottom: calc(5px + env(safe-area-inset-bottom));
  }
`

const Controls = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 15% 70% 15%;

  @media screen and (max-width: 480px) {
    grid-template-columns: 100%;
    width: 91%;
    margin: 0 auto;
  }
  @media screen and (orientation: landscape) and (min-device-width: 375px) and (max-device-width: 812px) {
    width: 91%;
    margin: 0 auto;
  }
`

const Name = styled.p`
  font-size: 24px;
  text-align: center;
`

const Question = styled.p`
  font-size: 24px;
`

const FormContainer = styled.div`
  max-height: calc(100% - 330px);
  overflow: auto;
  @media screen and (max-width: 1366px) {
    margin: 0 1rem 2rem;
  }

  @media screen and (max-width: 768px) {
    margin: 0;
  }

  @media screen and (max-width: 480px) {
    max-height: 340px;
  }
  ::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
`

const BlueRadio = withStyles({
  root: {
    color: '#026eb7',
    '&$checked': {
      color: '#026eb7',
    },
  },
})((props) => <Radio color="default" {...props} />)

const Seleccion = ({
  agrupadores,
  asignaciones,
  questionIndex,
  reactivos,
  answerData,
  evalInfo,
  setAnswerData,
}) => {
  const [canContinue, setCanContinue] = useState(false)

  const agrupadorActual = agrupadores.filter((agrupador) =>
    agrupador.reactivos.includes(reactivos[questionIndex])
  )[0]

  const handleChange = (res, val, id_res) => {
    if (
      answerData.respuestas.find(
        (respuesta) => respuesta.id_agrupador === agrupadorActual.id
      )
    ) {
      const respuestasFiltradas = answerData.respuestas.filter(
        (respuesta) => respuesta.id_reactivo !== reactivos[questionIndex].id
      )

      setAnswerData({
        ...answerData,
        respuestas: [
          ...respuestasFiltradas,
          {
            id_respuesta: id_res,
            id_agrupador: agrupadorActual.id,
            id_reactivo: reactivos[questionIndex].id,
            clave_materia: asignaciones[0].clave,
            clave_docente: asignaciones[0].clave_docente,
            id_asignacion: asignaciones[0].id,
            id_student_assignment: asignaciones[0].id_student_assignment,
            respuesta: res,
            valor_respuesta: val,
            tipo: 'seleccion',
          },
        ],
      })
    } else {
      setAnswerData({
        ...answerData,
        respuestas: [
          ...answerData.respuestas,
          {
            id_respuesta: id_res,
            id_agrupador: agrupadorActual.id,
            id_reactivo: reactivos[questionIndex].id,
            clave_materia: asignaciones[0].clave,
            clave_docente: asignaciones[0].clave_docente,
            id_asignacion: asignaciones[0].id,
            id_student_assignment: asignaciones[0].id_student_assignment,
            respuesta: res,
            valor_respuesta: val,
            tipo: 'seleccion',
          },
        ],
      })
    }
  }

  const question = reactivos[questionIndex]?.pregunta

  const matches = useMediaQuery('(max-width:480px)')

  const respuestaFiltrada = answerData.respuestas.filter(
    (respuesta) => reactivos[questionIndex].id === respuesta.id_reactivo
  )

  const newCanContinue = respuestaFiltrada.length > 0
  canContinue !== newCanContinue && setCanContinue(newCanContinue)

  return (
    <>
      <InicioContainer>
        <Name>{`${agrupadorActual?.numero}.${agrupadorActual?.nombre}`}</Name>

        <Question>{`${agrupadorActual?.numero}.${reactivos[questionIndex]?.numero} ${question}`}</Question>
        <FormControl component="fieldset">
          <RadioGroup>
            {reactivos[questionIndex]?.opcions.map(({ nombre, id }, i) => (
              <FormControlLabel
                key={i}
                value={i}
                control={
                  <BlueRadio onChange={() => handleChange(nombre, i, id)} />
                }
                label={nombre}
                checked={
                  respuestaFiltrada.length > 0 &&
                  respuestaFiltrada[0]?.valor_respuesta === i
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
        <ButtonNext canContinue={canContinue} />
      </InicioContainer>

      <ControlsWrapper>
        <Controls>
          {!matches && <ButtonExit />}
          <Progress canContinue={canContinue} />
        </Controls>
      </ControlsWrapper>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    agrupadores: state.agrupadores,
    asignaciones: state.asignaciones,
    questionIndex: state.questionIndex,
    reactivos: state.reactivos,
    answerData: state.answerData,
    evalInfo: state.evalInfo,
  }
}

const mapDispatchToProps = {
  setAnswerData,
}

export default connect(mapStateToProps, mapDispatchToProps)(Seleccion)
