import styled, { css } from 'styled-components'

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 95%;
  margin: 0 auto;

  @media screen and (max-width: 600px) {
    width: 92%;
  }

  @media screen and (max-width: 500px) {
    width: 90%;
  }
`

export const TH = styled.th`
  max-width: 30px;
  background-color: ${({ dark }) => (dark ? '#E2E2E2' : 'white')};

  @media screen and (max-width: 768px) {
    height: 80px;
    width: 20px;
  }
`

export const TD = styled.td`
  min-width: 35px;
  text-align: center;
  position: relative;
  background-color: ${({ odd, dark }) => {
    if (odd && dark) {
      return '#C2C2C2'
    } else if (odd && !dark) {
      return '#DFDFDF'
    } else if (!odd && dark) {
      return '#DEDEDE'
    } else {
      return '#FFFFFF'
    }
  }};
  cursor: ${({ cursor }) => cursor && cursor};

  ${({ isDuplicated }) =>
    isDuplicated &&
    css`
      &:after {
        content: '*';
        position: absolute;
        font-size: 30px;
        line-height: 15px;
        left: -18px;
        top: 15px;
      }
    `}
`

export const OptionsText = styled.p`
  font-size: 13px;
  font-weight: 400;

  @media screen and (max-width: 768px) {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    margin: 0 auto;
    height: 100%;
    text-align: left;
    padding: 7px 0 0;
  }

  @media screen and (max-width: 480px) {
    height: initial;
  }
`

export const FirstOptionsText = styled.p`
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin: 6px 0;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`

export const Bar = styled.div`
  border: 1px solid gray;
  width: 100%;
  height: 6px;
  margin: -3px;
  border-right: none;
  border-left: none;
  z-index: 50;
  background-color: ${({ selected }) => (selected ? '#026eb7' : '#fff')};
  position: relative;
  top: -9px;
  left: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 4px;
    top: -9px;
  }
`

export const Radius = styled.div`
  border: 1px solid gray;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin: 0 auto;
  position: relative;
`

export const Fill = styled.div`
  z-index: 60;
  width: 14px;
  height: 14px;
  border: none;
  border-radius: 50%;
  background-color: ${({ selected }) => (selected ? '#026eb7' : '#fff')};
  position: relative;
`
