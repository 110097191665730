import React from 'react'
import { connect } from 'react-redux'

import {
  setSelectedOptions,
  setAnswerData,
  setNextAgrupadores,
} from '../../actions'
import TableRow from './TableRow'
import { StyledTable, TH, OptionsText } from './styles'

const SeleccionTableItem = ({
  asignaciones,
  agrupadores,
  reactivos,
  setAnswerData,
  answerData,
  reactivo,
  questionIndex,
  index,
  setNextAgrupadores,
  nextAgrupadores,
  hasBeenSkipped,
}) => {
  const handleClick = (asig, res, val, id_res) => {
    let agrupadorActual
    agrupadores.forEach((agrupador) => {
      if (agrupador.reactivos.includes(reactivos[questionIndex])) {
        agrupadorActual = agrupador
      }
    })

    const newRes = []

    asignaciones.forEach((asignacion) => {
      if (
        asig.clave === asignacion.clave &&
        asig.nombre === asignacion.nombre &&
        asig.docente === asignacion.docente
      ) {
        newRes.push({
          id_respuesta: id_res,
          id_agrupador: agrupadorActual.id,
          id_reactivo: reactivos[questionIndex + index].id,
          clave_materia: asignacion.clave,
          clave_docente: asignacion.clave_docente,
          id_asignacion: asignacion.id,
          id_student_assignment: asignacion.id_student_assignment,
          respuesta: res,
          valor_respuesta: val,
          tipo: 'seleccion',
        })
      }
    })
    if (
      answerData.respuestas.find(
        (respuesta) =>
          respuesta.id_asignacion === asig.id &&
          respuesta.id_agrupador === agrupadorActual.id
      )
    ) {
      const respuestasFiltradas = answerData.respuestas.filter(
        (respuesta) =>
          respuesta.clave_materia !== asig.clave ||
          respuesta.clave_docente !== asig.clave_docente ||
          respuesta.id_reactivo !== reactivos[questionIndex + index].id
      )

      setAnswerData({
        ...answerData,
        respuestas: [...respuestasFiltradas, ...newRes],
      })

      if (
        agrupadorActual.reactivos[0]['condiciones_redireccion'] &&
        agrupadorActual.reactivos[0]['condiciones_redireccion'].length > 0
      ) {
        const redireccionItem = agrupadorActual.reactivos[0][
          'condiciones_redireccion'
        ].find((item) => item.nombre === res || item.id_opcion === id_res)

        let nextAgrupadorName = ''
        let nextAgrupadorExist = false

        if (redireccionItem) {
          nextAgrupadorName = redireccionItem.redireccion
          nextAgrupadorExist = true
        }

        const filteredAsig = nextAgrupadores[nextAgrupadorName]
          ? nextAgrupadores[nextAgrupadorName].filter(
              (item) => item.id !== asig.id
            )
          : []

        let filteredNextAgrupadores = JSON.parse(
          JSON.stringify(nextAgrupadores)
        )

        for (let i = 0; i < Object.keys(filteredNextAgrupadores).length; i++) {
          const curAgr = agrupadores.find(
            (el) => el.nombre === Object.keys(filteredNextAgrupadores)[i]
          )
          if (curAgr.numero > agrupadorActual.numero) {
            let sub = []
            filteredNextAgrupadores[
              Object.keys(filteredNextAgrupadores)[i]
            ].forEach((item) => {
              if (item.id !== asig.id) {
                sub.push(item)
              }
            })

            filteredNextAgrupadores[Object.keys(filteredNextAgrupadores)[i]] =
              sub
          }
        }

        if (nextAgrupadorExist) {
          const newNextAgrupadores = {
            ...filteredNextAgrupadores,
            [nextAgrupadorName]: [...filteredAsig, asig],
          }
          setNextAgrupadores(newNextAgrupadores)
        }
      } else {
        if (agrupadores[questionIndex + 1]) {
          let filteredNextAgrupadores = {}

          for (let i = 0; i < Object.keys(nextAgrupadores).length; i++) {
            if (
              agrupadores[questionIndex + 1].nombre !==
              Object.keys(nextAgrupadores)[i].nombre
            ) {
              filteredNextAgrupadores[Object.keys(nextAgrupadores)[i]] =
                nextAgrupadores[Object.keys(nextAgrupadores)[i]]
            }
          }

          if (
            nextAgrupadores[agrupadores[questionIndex + 1].nombre] &&
            nextAgrupadores[agrupadores[questionIndex + 1].nombre].length > 0
          ) {
            let newAsigs =
              nextAgrupadores[agrupadores[questionIndex + 1].nombre]
            const element = nextAgrupadores[
              agrupadores[questionIndex + 1].nombre
            ].find((el) => el.id === asig.id)
            if (!element) {
              newAsigs = [...newAsigs, asig]

              const newNextAgrupadores = {
                ...filteredNextAgrupadores,
                [agrupadores[questionIndex + 1].nombre]: newAsigs,
              }
              setNextAgrupadores(newNextAgrupadores)
              return
            }
          }

          const newNextAgrupadores = {
            ...filteredNextAgrupadores,
            [agrupadores[questionIndex + 1].nombre]:
              filteredNextAgrupadores[agrupadorActual.nombre],
          }
          setNextAgrupadores(newNextAgrupadores)
        }
      }
    } else {
      setAnswerData({
        ...answerData,
        respuestas: [...answerData.respuestas, ...newRes],
      })

      if (
        agrupadorActual.reactivos[0]['condiciones_redireccion'] &&
        agrupadorActual.reactivos[0]['condiciones_redireccion'].length > 0
      ) {
        const redireccionItem = agrupadorActual.reactivos[0][
          'condiciones_redireccion'
        ].find((item) => item.nombre === res || item.id_opcion === id_res)

        let nextAgrupadorName = ''
        let nextAgrupadorExist = false

        if (redireccionItem) {
          nextAgrupadorName = redireccionItem.redireccion
          nextAgrupadorExist = true
        }

        const filteredAsig = nextAgrupadores[nextAgrupadorName]
          ? nextAgrupadores[nextAgrupadorName].filter(
              (item) => item.id !== asig.id
            )
          : []

        let filteredNextAgrupadores = JSON.parse(
          JSON.stringify(nextAgrupadores)
        )

        for (let i = 0; i < Object.keys(filteredNextAgrupadores).length; i++) {
          const name = Object.keys(filteredNextAgrupadores)[i]
          if (i > agrupadorActual.nombre) {
            let sub = []
            filteredNextAgrupadores[name].forEach((item) => {
              if (item.id !== asig.id) {
                sub.push(item)
              }
            })

            filteredNextAgrupadores[name] = sub
          }
        }

        if (nextAgrupadorExist) {
          const newNextAgrupadores = {
            ...filteredNextAgrupadores,
            [nextAgrupadorName]: [...filteredAsig, asig],
          }
          setNextAgrupadores(newNextAgrupadores)
        } else {
          let nextAgrupador

          const sortedAgrupadores = agrupadores.sort(
            (a, b) => a.numero - b.numero
          )

          sortedAgrupadores.forEach((item, i) => {
            if (
              nextAgrupadores[item.nombre] &&
              nextAgrupadores[item.nombre].length > 0
            ) {
              if (!nextAgrupador || i < nextAgrupador) {
                if (i > questionIndex) {
                  nextAgrupador = i
                }
              }
            }
          })

          let filteredNextAgrupadores = {}

          for (let i = 0; i < Object.keys(nextAgrupadores).length; i++) {
            if (
              agrupadores[questionIndex + 1].nombre !==
              Object.keys(nextAgrupadores)[i].nombre
            ) {
              filteredNextAgrupadores[Object.keys(nextAgrupadores)[i]] =
                nextAgrupadores[Object.keys(nextAgrupadores)[i]]
            }
          }

          let newAsigs = nextAgrupadores[agrupadores[nextAgrupador].nombre]

          for (
            let i = 0;
            i < nextAgrupadores[agrupadorActual.nombre].length;
            i++
          ) {
            const element = nextAgrupadores[agrupadorActual.nombre][i]

            if (!newAsigs.find((el) => el.id === element.id)) {
              newAsigs = [...newAsigs, element]
            }
          }

          const newNextAgrupadores = {
            ...nextAgrupadores,
            [agrupadores[nextAgrupador].nombre]: newAsigs,
          }
          setNextAgrupadores(newNextAgrupadores)
        }
      } else {
        if (agrupadores[questionIndex + 1]) {
          let filteredNextAgrupadores = {}

          for (let i = 0; i < Object.keys(nextAgrupadores).length; i++) {
            if (
              agrupadores[questionIndex + 1].nombre !==
              Object.keys(nextAgrupadores)[i].nombre
            ) {
              filteredNextAgrupadores[Object.keys(nextAgrupadores)[i]] =
                nextAgrupadores[Object.keys(nextAgrupadores)[i]]
            }
          }

          if (
            nextAgrupadores[agrupadores[questionIndex + 1].nombre] &&
            nextAgrupadores[agrupadores[questionIndex + 1].nombre].length > 0
          ) {
            let newAsigs =
              nextAgrupadores[agrupadores[questionIndex + 1].nombre]
            const element = nextAgrupadores[
              agrupadores[questionIndex + 1].nombre
            ].find((el) => el.id === asig.id)
            if (!element) {
              newAsigs = [...newAsigs, asig]

              const newNextAgrupadores = {
                ...filteredNextAgrupadores,
                [agrupadores[questionIndex + 1].nombre]: newAsigs,
              }
              setNextAgrupadores(newNextAgrupadores)
              return
            }
          }

          const newNextAgrupadores = {
            ...filteredNextAgrupadores,
            [agrupadores[questionIndex + 1].nombre]:
              filteredNextAgrupadores[agrupadorActual.nombre],
          }
          setNextAgrupadores(newNextAgrupadores)
        }
      }
    }
  }

  const options = reactivo.opcions

  const keys = []
  const keysDuplicadas = []
  const asignacionesVisibles = []

  asignaciones.forEach((asignacion) => {
    const exists = keys.find(
      (key) =>
        JSON.stringify(key) ===
        JSON.stringify({
          clave: asignacion.clave,
          nombre: asignacion.nombre,
          docente: asignacion.docente,
        })
    )
    if (!exists) {
      keys.push({
        clave: asignacion.clave,
        nombre: asignacion.nombre,
        docente: asignacion.docente,
      })

      asignacionesVisibles.push(asignacion)
    } else {
      keysDuplicadas.push({
        clave: asignacion.clave,
        nombre: asignacion.nombre,
        docente: asignacion.docente,
      })
    }
  })

  let agrupadorActual
  agrupadores.forEach((agrupador) => {
    if (agrupador.reactivos.includes(reactivos[questionIndex])) {
      agrupadorActual = agrupador
    }
  })

  let newAsignaciones = []

  if (
    nextAgrupadores[agrupadorActual.nombre] &&
    nextAgrupadores[agrupadorActual.nombre].length > 0
  ) {
    newAsignaciones = nextAgrupadores[agrupadorActual.nombre]
  } else {
    newAsignaciones = asignaciones
  }

  return (
    <StyledTable>
      <thead>
        <tr>
          <TH>
            <p
              style={{
                fontSize: '13px',
                fontWeight: '400',
                textAlign: 'left',
              }}
            >
              Asignatura
            </p>
          </TH>
          <TH>
            <p
              style={{
                fontSize: '13px',
                fontWeight: '400',
                textAlign: 'left',
              }}
            >
              Docente
            </p>
          </TH>
          {options.map(({ id, nombre }, i) => (
            <TH key={id} dark={i % 2 === 0}>
              <OptionsText>{nombre}</OptionsText>
            </TH>
          ))}
        </tr>
      </thead>
      <tbody>
        {newAsignaciones && newAsignaciones.length > 0
          ? newAsignaciones.map((asignacion, i) => {
              const isDuplicated = keysDuplicadas.find(
                (key) =>
                  JSON.stringify(key) ===
                  JSON.stringify({
                    clave: asignacion.clave,
                    nombre: asignacion.nombre,
                    docente: asignacion.docente,
                  })
              )

              return (
                <TableRow
                  isDuplicated={isDuplicated}
                  key={asignacion.id}
                  index={i}
                  id={asignacion.id}
                  asignatura={asignacion.nombre}
                  docente={asignacion.docente}
                  options={options}
                  reactivo={reactivo}
                  handleClick={(res, val, id_res) =>
                    handleClick(asignacion, res, val, id_res)
                  }
                />
              )
            })
          : asignacionesVisibles.map((asignacion, i) => {
              const isDuplicated = keysDuplicadas.find(
                (key) =>
                  JSON.stringify(key) ===
                  JSON.stringify({
                    clave: asignacion.clave,
                    nombre: asignacion.nombre,
                    docente: asignacion.docente,
                  })
              )

              return (
                <TableRow
                  isDuplicated={isDuplicated}
                  key={asignacion.id}
                  index={i}
                  id={asignacion.id}
                  asignatura={asignacion.nombre}
                  docente={asignacion.docente}
                  options={options}
                  reactivo={reactivo}
                  handleClick={(res, val, id_res) =>
                    handleClick(asignacion, res, val, id_res)
                  }
                />
              )
            })}
      </tbody>
    </StyledTable>
  )
}

const mapStateToProps = (state) => ({
  answerData: state.answerData,
  asignaciones: state.asignaciones,
  agrupadores: state.agrupadores,
  reactivos: state.reactivos,
  questionIndex: state.questionIndex,
  nextAgrupadores: state.nextAgrupadores,
  hasBeenSkipped: state.hasBeenSkipped,
})

const mapDispatchToProps = {
  setSelectedOptions,
  setAnswerData,
  setNextAgrupadores,
}

export default connect(mapStateToProps, mapDispatchToProps)(SeleccionTableItem)
