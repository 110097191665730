import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'

import Modal from '../components/Modal'
import { ButtonExit } from '../components/Buttons'
import { getRequest } from '../utils/requester'

import {
  setType,
  setAsignaciones,
  setAgrupadores,
  setEvalInfo,
  setReactivos,
  setAnswerData,
  setIsList,
  setRawType,
  setDuration,
} from '../actions'

const InstruccionesContainer = styled.div`
  margin: 3rem;
  @media screen and (max-width: 480px) {
    margin: 3rem 1rem;
  }
`

const Text = styled.p`
  font-size: 21px;
  @media screen and (max-width: 480px) {
    font-size: 18px;
    text-align: center;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 5rem 0 0;
  @media screen and (max-width: 480px) {
    margin: 3rem 0 0;
  }
`

const CotainerLoader = styled.div`
  text-align: center;
`

const Button = styled.button`
  width: 410px;
  height: 42px;
  background-color: #026eb7;
  border-radius: 40px;
  font-size: 22px;
  color: white;
  cursor: pointer;
  border: none;
  @media screen and (max-width: 480px) {
    width: 300px;
    height: 50px;
    font-size: 20px;
  }
`

const Instrucciones = ({
  match,
  setType,
  evals,
  setAsignaciones,
  setAgrupadores,
  setEvalInfo,
  setReactivos,
  setAnswerData,
  setIsList,
  answerData,
  setRawType,
  setDuration,
  setModal,
}) => {
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const thisEval = evals.find(
    (evaluacion) => evaluacion.id.toString() === match.params.id
  )

  useEffect(() => {
    thisEval && setRawType(thisEval.tipo)
  }, [thisEval])

  setType(match.params.type)

  const history = useHistory()

  const getEvals = async (id) => {
    try {
      setLoading(true)
      let data = await getRequest(`/evaluacion/alumno/detalle?id=${id}`)
      // const response = await fetch(
      //   'http://159.203.83.182/evaluacion-alumno/obtenerEvaluaciones_Evaluaciones.php',
      //   {
      //     method: 'GET',
      //     mode: 'cors',
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //   },
      // );
      // const data = await response.json();
      data.d.tipo_evaluacion = 'Evaluacion_Temprana'
      setAsignaciones(data.d.asignaciones)
      setAgrupadores(data.d.agrupadores)
      setEvalInfo({
        id: data.d.id,
        clave: data.d.clave,
        tipo_evaluacion: data.d.tipo_evaluacion,
        nombre: data.d.nombre,
        descripcion: data.d.descripcion,
        nivel: data.d.nivel,
      })
      setAnswerData({
        id_user: answerData.id_user,
        clave_user: answerData.clave_user,
        clave: data.d.clave,
        folio: data.d.id.toString(),
        id_campus: data.d.id_campus,
        id_nivel: data.d.id_nivel,
        id_evaluacion: data.d.id,
        tipo: data.d.tipo_evaluacion,
        respuestas: [],
      })

      setLoading(false)

      if (
        data.d.tipo_evaluacion === 'Opinion_Estudiantil' ||
        data.d.tipo_evaluacion === 'Evaluaciones' ||
        data.d.tipo_evaluacion === 'Evaluacion_Temprana'
      ) {
        setIsList(true)
      } else {
        setIsList(false)
      }

      if (data.d.tipo_evaluacion === 'Evaluaciones') {
        setDuration(data.d.temporizador)
      }

      let reactivos = []

      data.d.agrupadores.forEach((agrupador) => {
        reactivos.push(...agrupador.reactivos)
      })

      setReactivos(reactivos)
      history.push('/presentacion')
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const onClick = () => {
    if (
      match.params.type === 'Tipo_ENE' ||
      match.params.type === 'Evaluaciones'
    ) {
      setIsModalOpen(true)
    } else {
      getEvals(match.params.id)
    }
  }
  const onContinue = () => {
    getEvals(match.params.id)
  }

  return (
    <>
      <InstruccionesContainer>
        <Text>
          <div dangerouslySetInnerHTML={{ __html: thisEval?.descripcion }} />
        </Text>

        <ButtonContainer>
          <Button onClick={onClick}>
            {loading ? (
              <CotainerLoader>
                <CircularProgress size={40} color="white" />
              </CotainerLoader>
            ) : (
              'Iniciar evaluación'
            )}
          </Button>
        </ButtonContainer>
      </InstruccionesContainer>
      <ButtonExit />
      <Modal
        type="eval"
        title="Confirmar iniciar evaluación"
        description="Al oprimir continuar tu prueba iniciará oficialmente y no podrás salir hasta concluirla."
        message="¿Estas seguro de que quieres continuar?"
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        handleContinue={onContinue}
        loading={loading}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  evals: state.evals,
  answerData: state.answerData,
})

const mapDispatchToProps = {
  setType,
  setAsignaciones,
  setAgrupadores,
  setEvalInfo,
  setReactivos,
  setAnswerData,
  setIsList,
  setRawType,
  setDuration,
}

export default connect(mapStateToProps, mapDispatchToProps)(Instrucciones)
