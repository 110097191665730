import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import BotonSafeKey from '../../assets/images/ICONOS-20.png'

import {
  FormPage,
  FormContainer,
  Form,
  ButtonContainer,
  Button,
  ButtonText,
  Image,
  Text1,
  Title,
} from './styles'

import logo from '../../assets/images/ICONOS-19.png'

export const UserForm = ({ onSubmit, loading }) => {
  let loaderSize

  const tablet = useMediaQuery('(max-width:768px)')
  const mobile = useMediaQuery('(max-width:425px)')

  if (!tablet && !mobile) {
    loaderSize = 40
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit()
  }

  return (
    <FormPage>
      <FormContainer>
        <Image src={logo} alt="logo-unitec" />
        <Title>SIEE-Acceso Estudiantes</Title>
        <Text1>Cualquier problema para ingresar con tu email MyUnitec,
        envía un correo a: <a href = "mailto:  ayuda@my.unitec.edu.mx">ayuda@my.unitec.edu.mx</a> </Text1>
        <Form onSubmit={handleSubmit}>
          <ButtonContainer>
            {loading ? (
              <div className="loader-container">
                <CircularProgress size={40} color="inherit" />
              </div>
            ) : (
              <Button type="submit" disabled={loading}>
                <ButtonText>
                  <nobr>
                    Inicia sesión con <b>SAFE KEY UNITEC</b>
                  </nobr>
                </ButtonText>
                <img src={BotonSafeKey} alt="boton_safekey" />
              </Button>
            )}
          </ButtonContainer>
        </Form>
      </FormContainer>
    </FormPage>
  )
}
