import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'

import { getRequest, postRequest } from '../utils/requester'

import {
  clearAnswerData,
  clearEvalInfo,
  setQuestionIndex,
  setEvals,
  setDuration,
  setLoadingRespuesta,
  setErrorEvaluacion,
} from '../actions'
import palomita from '../assets/images/PALOMITA-47.png'

const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  @media screen and (max-width: 480px) {
    margin: 2rem;
  }
`
const CotainerLoader = styled.div`
  text-align: center;
`
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 600px;
  flex-direction: column;
  @media screen and (max-width: 600px) {
    max-width: 450px;
  }
  @media screen and (max-width: 480px) {
    max-width: 100%;
  }
`

const TextContainer = styled.div`
  background-color: #e2e2e2;
  display: flex;
  justify-content: space-around;
  height: 35px;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
  @media screen and (max-width: 480px) {
    height: 25px;
  }
`

const H1 = styled.h1`
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  @media screen and (max-width: 480px) {
    font-size: 18px;
    text-align: center;
  }
`

const Text = styled.p`
  font-size: 18px;
  @media screen and (max-width: 480px) {
    font-size: 17px;
  }
`

export const Bold = styled.p`
  font-weight: 700;
  font-size: 18px;
  text-align: left;
`

const Button = styled.button`
  width: 300px;
  height: 43px;
  background-color: #026eb7;
  border-radius: 40px;
  font-size: 24px;
  color: white;
  cursor: pointer;
  border: none;
  @media screen and (max-width: 480px) {
    width: 300px;
    height: 50px;
    font-size: 20px;
  }
`

const Img = styled.img`
  width: 110px;
  margin: 0 auto;
  @media screen and (max-width: 480px) {
    width: 80px;
    height: 80px;
  }
`

const StyledLink = styled(Link)`
  text-align: center;
`

const Info = styled.span`
  text-align: center;
  font-size: 22px;
  margin-bottom: 8px;
`

const NextButton = styled.button`
  background-color: #108548;
  margin: 10px auto;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  padding: 10px 25px;
  font-size: 16px;
`

const FinEvaluacion = ({
  user,
  type,
  clearAnswerData,
  clearEvalInfo,
  setQuestionIndex,
  evals,
  setEvals,
  answerData,
  loadingRespuesta,
  errorEvaluacion,
  evalInfo,
  setDuration,
  setLoadingRespuesta,
  setErrorEvaluacion,
  duration,
}) => {
  const history = useHistory()
  const [nextEval, setNextEval] = useState(null)
  const [text1, setText1] = useState('')
  const [text2, setText2] = useState('')
  const [text3, setText3] = useState('')
  const [hasFinished, setHasFinished] = useState(false)

  useEffect(() => {
    if (duration === null) {
      setHasFinished(false)
    } else {
      setHasFinished(true)
      getResults()
    }
    getNextEval()
    console.log(type)
    type !== 'Evaluaciones' && sendAnswers()
    setDuration(null)
  }, [])

  const sendAnswers = async () => {
    const respuestas = answerData.respuestas.map((respuesta) => {
      if (
        answerData.tipo !== 'Opinion_Estudiantil' &&
        answerData.tipo !== 'Evaluacion_Temprana'
      ) {
        delete respuesta.clave_docente
        delete respuesta.clave_materia
      }
      delete respuesta.valor_respuesta
      return respuesta
    })

    setLoadingRespuesta(true)
    setErrorEvaluacion('')
    try {
      const response = await postRequest('/evaluacion/responder', answerData)
      if (true) {
        setLoadingRespuesta(false)
      } else {
        setLoadingRespuesta(false)
        setErrorEvaluacion(response.m)
      }
    } catch (error) {
      setErrorEvaluacion('Ocurrió un error al enviar respuestas')
      setLoadingRespuesta(false)
      console.log(error)
    }
  }

  const getType = (tipo) => {
    switch (tipo) {
      case 'Taller/Laboratorio':
        return 'Taller_Laboratorio'

      case 'Opinion_Estudiantil':
        return 'Opinion_Estudiantil'

      case 'Tipo ENE':
        return 'Tipo_ENE'

      case 'Encuestas':
        return 'Encuestas'

      case 'Evaluaciones':
        return 'Evaluaciones'

      case 'Evaluacion_Temprana':
        return 'Evaluacion_Temprana'

      default:
        return
    }
  }

  const getNextEval = async () => {
    console.log(type)
    try {
      const data = await getRequest('/evaluacion/alumno')

      setEvals(data.d)

      const newEval = data.d.find((evaluacion) => {
        if (type === 'Encuestas') {
          return (
            (evaluacion.tipo === 'Opinion_Estudiantil' ||
              evaluacion.tipo === 'Evaluacion_Temprana') &&
            evaluacion.status === 'pendiente'
          )
        } else if (
          type === 'Opinion_Estudiantil' ||
          type === 'Evaluacion_Temprana'
        ) {
          return (
            evaluacion.tipo === 'Encuestas' && evaluacion.status === 'pendiente'
          )
        }
      })

      setNextEval(newEval)
    } catch (error) {
      console.log(error)
    }
  }

  const getResults = async () => {
    try {
      const response = await fetch(
        `http://159.203.83.182/evaluacion-alumno/obtenerResultado.php?id=${evalInfo.id}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      const data = await response.json()
      setText1(data.d.titulo)
      setText2(data.d.desempeño)
      setText3(data.d.retroalimentación)
    } catch (error) {
      console.error(error)
    }
  }

  const handleClick = () => {
    clearAnswerData()
    clearEvalInfo()
    setQuestionIndex(0)
  }

  const handleNext = () => {
    if (
      type === 'Encuestas' ||
      type === 'Opinion_Estudiantil' ||
      type === 'Evaluacion_Temprana'
    ) {
      clearAnswerData()
      clearEvalInfo()
      setQuestionIndex(0)
      history.push(`/instrucciones/${getType(nextEval.tipo)}/${nextEval.id}`)
    }
  }

  return (
    <Container>
      <Wrapper>
        {loadingRespuesta ? (
          <CotainerLoader>
            <CircularProgress size={70} color="secondary" />
          </CotainerLoader>
        ) : (
          <>
            {errorEvaluacion !== '' ? (
              <H1>{errorEvaluacion}</H1>
            ) : (
              <>
                <Img src={palomita} alt="palomita" />
                <H1>
                  Gracias {user.nombre || ''}, has concluido tu Evaluación
                </H1>
              </>
            )}

            {hasFinished ? (
              <div style={{ marginBottom: '30px' }}>
                <Text style={{ color: '#026eb7', margin: '3px 0' }}>
                  {text1}
                </Text>
                <Text style={{ margin: '3px 0' }}>{text2}</Text>
                {text3 && (
                  <>
                    <Bold>Retroalimentación:</Bold>
                    <Text style={{ margin: '3px 0' }}>{text3}</Text>
                  </>
                )}
              </div>
            ) : (
              !['Encuestas', 'Evaluacion_Temprana'].includes(type) && (
                <div style={{ marginBottom: '30px' }}>
                  <p style={{ textAlign: 'center', fontSize: '22px' }}>
                    
                  </p>
                </div>
              )
            )}

            {nextEval && errorEvaluacion === '' ? (
              <>
                <Info>Tienes una evaluación pendiente</Info>
                <NextButton onClick={handleNext}>
                  Iniciar {nextEval.tipo}
                </NextButton>
              </>
            ) : (
              <StyledLink to="/">
                <Button onClick={handleClick}>Regresar a Inicio</Button>
              </StyledLink>
            )}
          </>
        )}
      </Wrapper>
    </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    type: state.type,
    user: state.user,
    evals: state.evals,
    answerData: state.answerData,
    loadingRespuesta: state.loadingRespuesta,
    errorEvaluacion: state.errorEvaluacion,
    evalInfo: state.evalInfo,
    duration: state.duration,
  }
}

const mapDispatchToProps = {
  clearAnswerData,
  clearEvalInfo,
  setQuestionIndex,
  setEvals,
  setDuration,
  setLoadingRespuesta,
  setErrorEvaluacion,
}

export default connect(mapStateToProps, mapDispatchToProps)(FinEvaluacion)
